<template>
  <div>
    <v-app-bar app fixed dense color="primary" dark>
      <v-app-bar-nav-icon @click.stop="$emit('hideMenu', !showMenu)">
      </v-app-bar-nav-icon>
      <v-toolbar-title
        ><v-icon class="mb-1 mr-4" large> mdi-cloud-outline </v-icon>
        Clomos - RotNet
      </v-toolbar-title>
      <v-spacer></v-spacer>
      <span class="text--disabled white--text text-h8 text-capitalize">{{
        ambiente + "." + version
      }}</span>
      <!-- <span class="text--disabled white--text text-h7">{{
        " - " + appApi
      }}</span> -->
      <v-spacer></v-spacer>
      <template>
        <v-btn icon>
          <v-icon>mdi-account-circle-outline</v-icon>
        </v-btn>
        <span> {{ usuario }} </span>
      </template>
      <template>
        <v-btn icon>
          <v-icon>mdi-license</v-icon>
        </v-btn>
        <span> {{ licenca }} </span>
      </template>

      <v-btn icon disabled @click="metEnviaEmail">
        <v-icon>mdi-email-outline</v-icon>
      </v-btn>

      <v-btn icon disabled>
        <v-icon>mdi-bell-outline</v-icon>
      </v-btn>

      <v-btn icon disabled>
        <v-icon>mdi-cog-outline</v-icon>
      </v-btn>

      <v-tooltip bottom>
        <template v-slot:activator="{ on, attrs }">
          <v-btn icon @click="recarregaApp">
            <v-icon v-bind="attrs" v-on="on">mdi-cloud-refresh</v-icon>
          </v-btn>
        </template>
        <span>Limpar cache</span>
      </v-tooltip>

      <v-tooltip bottom>
        <template v-slot:activator="{ on, attrs }">
          <v-btn icon @click="sair">
            <v-icon v-bind="attrs" v-on="on">mdi-exit-to-app</v-icon>
          </v-btn>
        </template>
        <span>Sair</span>
      </v-tooltip>

      <v-menu left bottom>
        <template v-slot:activator="{ on, attrs }">
          <v-btn icon v-bind="attrs" v-on="on">
            <v-icon>mdi-dots-vertical</v-icon>
          </v-btn>
        </template>

        <v-list>
          <v-list-item
            :disabled="true"
            v-for="n in 3"
            :key="n"
            @click="() => {}"
          >
            <v-list-item-title>Opção {{ n }}</v-list-item-title>
          </v-list-item>
        </v-list>
      </v-menu>
    </v-app-bar>
  </div>
</template>

<script>
import { version } from "/package.json";
import axios from "../_services/axios";

export default {
  name: "ApliToolBar",
  // mixins: [],
  data: () => ({
    ambiente: process.env.NODE_ENV,
    version: version,
    appApi: process.env.VUE_APP_API,
    usu: undefined,
    usuario: undefined,
    licenca: undefined,
  }),
  props: {
    showMenu: Boolean,
  },
  model: {
    prop: "showMenu",
    event: "hideMenu",
  },

  created() {
    this.usuario = this.$store.state.login.usuario.nome;
    this.licenca = this.$store.state.login.licenca;
  },

  methods: {
    sair: function () {
      this.$router.replace(`/?licenca=${this.$store.state.login.licenca}`);
    },

    recarregaApp() {
      // window.location.reload(true);
      // eslint-disable-next-line no-self-assign
      window.location.href = window.location.href;
    },

    async metEnviaEmail({ email }) {
      email = {
        from: "Tarefas <rotnet@rotnet.com.br",
        to: "luizcarlos@rotnet.com.br",
        replayTo: "suporte@rotnet.com.br",
        subject: "Email Teste",
        text: "Email enviado do Clomos como teste.",
      };

      try {
        const response = await axios.postMail(email);
        this.$root.snackbar.show({
          type: "info",
          message: response.data.response,
        });
      } catch (error) {
        this.$root.snackbar.show({
          type: "danger",
          message: error,
        });
      }
    },
  },
};
</script>
