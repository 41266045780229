<template>
  <div>
    <ApliToolBar v-model="drawer" />
    <ApliMenu v-model="drawer" />
    <v-container>
      <v-layout>
        <v-flex>
          <slot />
        </v-flex>
      </v-layout>
    </v-container>
  </div>
</template>

<script>
import ApliMenu from "./ApliMenu";
import ApliToolBar from "./ApliToolBar";

export default {
  name: "ApliLayout",
  components: { ApliMenu, ApliToolBar },

  data: () => ({
    drawer: false,
  }),
};
</script>
<style>
html {
  overflow-y: auto;
}
</style>
