<template>
  <v-navigation-drawer
    :value="value"
    @input="$emit('input', $event)"
    class="elevation-6"
    dense
    absolute
    expand-on-hover
    :permanent="value"
  >
    <v-list dense nav v-for="(item, i) in items" :key="i">
      <v-list-item
        dense
        :to="item.url"
        :exact="true"
        v-if="mixPermissao($options.name, item.name)"
      >
        <v-list-item-icon>
          <v-icon :color="item.color">{{ item.icon }} </v-icon>
        </v-list-item-icon>
        <v-list-item-content>
          <v-list-item-title v-text="item.text"></v-list-item-title>
        </v-list-item-content>
      </v-list-item>
    </v-list>
  </v-navigation-drawer>
</template>

<script>
import PermissoesMixin from "@/mixins/PermissoesMixin";

export default {
  name: "ApliMenu",
  mixins: [PermissoesMixin],
  props: {
    value: Boolean,
  },
  data: () => ({
    items: [
      {
        text: "Administrador",
        icon: "mdi-key-chain",
        color: "red darken-2",
        url: "/Administrador",
        name: "administrador",
      },
      {
        text: "Financeiro",
        icon: "mdi-hand-coin",
        color: "amber darken-1",
        url: "/Financeiro",
        name: "financeiro",
      },
      {
        text: "Cadastros",
        icon: "mdi-archive",
        color: "purple darken-2",
        url: "/Cadastros",
        name: "cadastros",
      },
      {
        text: "Portal Unico",
        icon: "mdi-police-station",
        color: "orange darken-3",
        url: "/PortalUnico",
        name: "portalunico",
      },
      {
        text: "Loja",
        icon: "mdi-store",
        color: "green darken-2",
        url: "/Loja",
        name: "loja",
      },
      {
        text: "Contabil",
        icon: "mdi-folder-account",
        color: "red darken-2",
        url: "/Contabil",
        name: "contabil",
      },
      {
        text: "Utilitários",
        icon: "mdi-apps-box",
        color: "blue darken-2",
        url: "/Utilitarios",
        name: "utilitarios",
      },
      {
        text: "Tabelas",
        icon: "mdi-database-cog",
        color: "indigo",
        url: "/Tabelas",
        name: "tabelas",
      },
      {
        text: "Consultas",
        icon: "mdi-jquery",
        color: "red darken-2",
        url: "/Consultas",
        name: "consultas",
      },
    ],
    user: {},
  }),
};
</script>

<style scoped>
.v-list--dense .v-list-item .v-list-item__title,
.v-list-item__subtitle {
  display: flex;
  flex-direction: row;
  justify-content: left;
  /* color: #2a2a2a; */
  font-size: 1em;
  font-weight: normal;
  line-height: normal;
  text-align: left;
  word-wrap: break-word;
}
.v-list {
  padding: 0;
}
.v-navigation-drawer {
  padding-top: 8px;
}
</style>
