<template>
  <ApliLayout>
    <router-view />
    <SnackBar ref="snackbar" />
  </ApliLayout>
</template>

<script>
import ApliLayout from "./../_components/ApliLayout";
import SnackBar from "../../../components/SnackBar";

export default {
  name: "Aplicacao",
  components: { ApliLayout, SnackBar },
  mounted() {
    this.$root.snackbar = this.$refs.snackbar;
  },
};
</script>
