<template>
  <div>
    <v-snackbar
      v-for="(snackbar, index) in staSnackbars.filter((s) => s.showing)"
      :key="snackbar.text + Math.random()"
      :color="snackbar.color"
      :icon="snackbar.icon"
      :timeout="-1"
      v-model="snackbar.showing"
      :style="`bottom: ${index * 60 + 8}px`"
      :centered="snackbar.poscentered"
      :top="snackbar.postop"
      :bottom="snackbar.posbottom"
      :right="snackbar.posright"
      :left="snackbar.posleft"
      app
      position="relative"
    >
      <v-icon left>{{ snackbar.icon }}</v-icon
      >{{ snackbar.text }}
    </v-snackbar>
  </div>
</template>

<script>
import { mapState, mapActions } from "vuex";

export default {
  name: "SnackBar",
  data() {
    return {};
  },
  computed: {
    ...mapState(["staSnackbars"]),
  },
  methods: {
    ...mapActions(["actShowSnackbar", "actClearSnackbar"]),
    show(data) {
      if (this.staSnackbars.length >= 8) {
        this.$store.dispatch("actClearSnackbar");
      }
      if (data.type == "success") {
        this.$store.dispatch("actShowSnackbar", {
          showing: true,
          text: data.message,
          color: "green darken-1",
          icon: "mdi-check-circle",
          indexing: Math.random(),
          postcentered: false,
          postop: false,
          posbottom: true,
          posright: true,
          posleft: false,
        });
      }
      if (data.type == "info") {
        this.$store.dispatch("actShowSnackbar", {
          showing: true,
          text: data.message,
          color: "blue darken-1",
          icon: "mdi-information",
          indexing: Math.random(),
          postcentered: false,
          postop: false,
          posbottom: true,
          posright: true,
          posleft: false,
        });
      }
      if (data.type == "warning") {
        this.$store.dispatch("actShowSnackbar", {
          showing: true,
          text: data.message,
          color: "yellow darken-4",
          icon: "mdi-alert",
          indexing: Math.random(),
          postcentered: false,
          postop: false,
          posbottom: true,
          posright: true,
          posleft: false,
        });
      }
      if (data.type == "danger") {
        this.$store.dispatch("actShowSnackbar", {
          showing: true,
          text: data.message,
          color: "red darken-1",
          icon: "mdi-close-octagon",
          indexing: Math.random(),
          postcentered: true,
          postop: true,
          posbottom: false,
          posright: false,
          posleft: false,
        });
      }
      if (data.type == "error") {
        this.$store.dispatch("actShowSnackbar", {
          showing: true,
          text: data.message,
          color: "blue-grey darken-2",
          icon: "mdi-close-box",
          indexing: Math.random(),
          postcentered: true,
          postop: true,
          posbottom: false,
          posright: false,
          posleft: false,
        });
      }
    },
  },
};
</script>
